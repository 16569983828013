// eslint-disable-next-line import/prefer-default-export
export const ParticipantTypeOptions = Object.freeze([
  {
    text: 'Teams',
    value: 1,
  },
  {
    text: 'Players',
    value: 2,
  },
])
