import { Timezones } from '@/constants/Timezones'
import Disciplines from '@/constants/Disciplines'
import DisciplineCategory from '@/constants/DisciplineCategory'
import Platforms from '@/constants/Platforms'
import TournamentFormat from '@/constants/TournamentFormat'
import { getUserLocale } from '@/views/common'

export default {
  methods: {
    getUserLocale,
  },
  computed: {
    timezonesList() {
      return Timezones.map(timezone => ({
        label: timezone.value,
        code: timezone.id,
      }))
    },
    organizer() {
      return this.$store.getters['organizer/getDashboardOrganizer']
    },
    fetchDisciplines() {
      if (!this.disciplineCategory) {
        return []
      }

      return Disciplines.filter(
        discipline => discipline.category_id === this.disciplineCategory,
      )
        .map(discipline => ({
          label: discipline.title,
          code: discipline.id,
        }))
    },
    fetchDisciplineCategories() {
      return DisciplineCategory.map(item => ({
        label: item.title,
        code: item.id,
      }))
    },
    fetchPlatforms() {
      return Platforms.map(item => ({
        label: item.title,
        code: Number(item.id),
      }))
    },
    generalFormatValidationRules() {
      return this.firstStageStructure === TournamentFormat.RACE
        ? ''
        : 'required'
    },
  },
}
