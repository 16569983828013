// eslint-disable-next-line import/prefer-default-export
export const StageTypeOptions = Object.freeze([
  {
    code: 1,
    label: 'Single Elimination',
  },
  {
    code: 2,
    label: 'Double Elimination',
  },
  {
    code: 3,
    label: 'Round Robin',
  },
  {
    code: 4,
    label: 'Race',
  },
])
