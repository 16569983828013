// eslint-disable-next-line import/prefer-default-export
export const MatchFormatOptions = Object.freeze([
  {
    code: 1,
    label: 'Best of 1',
  },
  {
    code: 2,
    label: 'Best of 2',
  },
  {
    code: 3,
    label: 'Best of 3',
  },
  {
    code: 4,
    label: 'Best of 4',
  },
  {
    code: 5,
    label: 'Best of 5',
  },
])
