// eslint-disable-next-line import/prefer-default-export
export const GroupRankingOptions = Object.freeze([
  {
    code: 1,
    label: 'Match wins',
  },
  {
    code: 2,
    label: 'Wins vs Tied participants',
  },
  {
    code: 3,
    label: 'Game/Set wins',
  },
  {
    code: 4,
    label: 'Points scored',
  }])
