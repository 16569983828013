<template>
  <div>
    <h4 class="mb-2">
      Create new tournament
    </h4>
    <p>
      You can later edit fields marked with
      <span class="text-primary">***</span>. Before and after tournament starts
      you can edit only fields marked with
      <span class="text-success">***</span>.
      <br>
      Try to enter accurate information when creating tournament.
      <b>Please check our
        <a href="#">documentation to understand settings</a>!</b>
    </p>

    <validation-observer ref="validation">
      <b-form @submit.prevent>
        <b-row>
          <!-- Tournament data -->
          <b-col
            sm="12"
            lg="9"
          >
            <!-- LEFT form data -->
            <b-card>
              <b-row>
                <b-col
                  lg="6"
                  md="12"
                >
                  <b-form-group
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    description="Example: League of Legends 5v5 EU West FUN"
                  >
                    <template v-slot:label>
                      <label>Tournament name (max 70 characters)
                        <span class="text-primary">***</span>
                      </label>
                    </template>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Tournament name"
                      rules="required"
                      vid="tournament-name"
                    >
                      <b-form-input
                        id="tournament-name"
                        v-model="name"
                        maxlength="70"
                      />
                      <small class="text-danger">
                        {{ errors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- discipline category -->
                  <b-form-group>
                    <template v-slot:label>
                      <label>Discipline category
                        <span class="text-primary">***</span></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Discipline category"
                      rules="required"
                    >
                      <v-select
                        id="discipline-category"
                        v-model="disciplineCategory"
                        :options="fetchDisciplineCategories"
                        :reduce="(category) => category.code"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- discipline  -->
                  <b-form-group>
                    <template v-slot:label>
                      <label>Discipline / game
                        <span class="text-primary">***</span></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Discipline"
                      rules="required"
                    >
                      <v-select
                        id="discipline"
                        v-model="discipline"
                        :options="fetchDisciplines"
                        :reduce="(d) => d.code"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- platforms -->
                  <b-form-group
                    description="if cross-play is supported you can select more than one"
                  >
                    <template v-slot:label>
                      <label>Select platform
                        <span class="text-primary">***</span></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Platform"
                      rules="required"
                    >
                      <v-select
                        id="platforms"
                        v-model="platforms"
                        :options="fetchPlatforms"
                        multiple
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- participants number -->
                  <b-form-group
                    label="Participant format "
                    label-for="participant-type"
                  >
                    <b-form-radio-group
                      id="participant-type"
                      v-model="participantsType"
                      :options="ParticipantTypeOptions"
                      name="participant-type"
                    />
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      <label>Maximum tournament participants (size)
                        <span class="text-primary">***</span></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Participants number"
                      rules="required"
                    >
                      <b-form-input
                        id="participants-number"
                        v-model="participantsNumber"
                        placeholder="Enter number"
                        type="number"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    v-if="participantsType === 1"
                    description="Enter minimum players that must be in team so the team is able to join"
                  >
                    <template v-slot:label>
                      <label>Number of players per team
                        <span class="text-primary">***</span></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Players per team"
                      rules="integer"
                    >
                      <b-form-input
                        id="players-per-team"
                        v-model="playersPerTeam"
                        placeholder="Enter number ..."
                        type="number"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Tournament start-->
                  <label>Tournament start</label>
                  <validation-provider
                    v-slot="{ errors: componentErrors }"
                    name="Tournament start"
                    rules="required"
                  >
                    <b-row>
                      <b-col cols="6">
                        <b-form-datepicker
                          v-model="startDate"
                          :locale="getUserLocale()"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                          :start-weekday="1"
                          class="mb-1"
                          name="start-date"
                          placeholder=""
                        />
                      </b-col>
                      <b-col cols="6">
                        <b-form-timepicker
                          v-model="startTime"
                          :hour12="false"
                          locale="en"
                          placeholder=""
                        />
                      </b-col>
                    </b-row>
                    <small class="text-danger">
                      {{ componentErrors[0] }}
                    </small>
                  </validation-provider>

                  <!-- Timezone -->
                  <b-form-group class="mt-1 mb-2">
                    <template v-slot:label>
                      <label>Timezone <span class="text-primary">***</span></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Timezone"
                      rules="required"
                    >
                      <v-select
                        id="timezone"
                        v-model="timezone"
                        :class="{ 'is-invalid': !!componentErrors.length }"
                        :options="timezonesList"
                        :reduce="(timezone) => timezone.code"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Participant & other info -->
                  <b-form-group description="HTML and CSS supported">
                    <template v-slot:label>
                      <label>Please add all info for participants, mention sponsors
                        etc.<span class="text-success">***</span></label>
                    </template>
                    <b-form-textarea
                      v-model="info"
                      name="participants-info"
                    />
                  </b-form-group>

                  <!-- Tournament rules -->
                  <b-form-group description="HTML and CSS supported">
                    <template v-slot:label>
                      <label>Please add only tournament rules
                        <span class="text-success">***</span></label>
                    </template>

                    <b-form-textarea
                      v-model="rules"
                      name="tournament-rules"
                    />
                  </b-form-group>
                </b-col>

                <!-- RIGHT form data -->
                <b-col
                  lg="6"
                  md="12"
                >
                  <!-- Two stage tournament -->
                  <b-form-checkbox
                    v-model="enableTwoStageTournament"
                    checked="true"
                    class="custom-control-primary"
                    name="enableTwoStageTournament"
                    disabled="disabled"
                  >
                    Two Stage Tournament
                  </b-form-checkbox>
                  <label
                    class="font-italic"
                  >* First stage groups, second stage proceed to second
                    stage</label>

                  <!-- First stage structure -->
                  <b-form-group>
                    <label
                      for="reason"
                    >Structure of first stage
                      <span class="text-primary">***</span></label>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="type"
                      rules="required"
                    >
                      <v-select
                        id="type"
                        v-model="firstStageStructure"
                        :options="StageTypeOptions"
                        :reduce="(type) => type.code"
                        name="type"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- General Format -->
                  <b-form-group
                    v-if="firstStageStructure === TournamentFormat.SINGLE_ELIMINATION || firstStageStructure === TournamentFormat.DOUBLE_ELIMINATION"
                    class="flex-fill"
                  >
                    <label for="reason">General format</label>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="format"
                      :rules="generalFormatValidationRules"
                    >
                      <v-select
                        id="type"
                        v-model="firstStageGeneralFormat"
                        :options="MatchFormatOptions"
                        :reduce="format => format.code"
                        name="format"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- If structure round robin show number of groups -->
                  <b-form-group
                    v-if="firstStageStructure === TournamentFormat.ROUND_ROBIN"
                    label="Number of groups"
                    label-for="firstStageNumberOfGroups"
                  >
                    <template v-slot:label>
                      <label>Number of groups <span class="text-primary">***</span><label /></label>
                    </template>

                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Number of groups"
                      rules="integer"
                    >
                      <b-form-input
                        id="firstStageNumberOfGroups"
                        v-model="firstStageNumberOfGroups"
                        type="number"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- If structure round robin show number of participants in each group -->
                  <b-form-group
                    v-if="firstStageStructure === TournamentFormat.ROUND_ROBIN"
                  >
                    <template v-slot:label>
                      <label>Participants in each group (max 20)
                        <span class="text-primary">***</span><label /></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Participants in group"
                      rules="integer"
                    >
                      <b-form-input
                        id="firstStageParticipantsPerGroup"
                        v-model="firstStageParticipantsPerGroup"
                        type="number"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- If structure round robin show participants to advance in next stage -->
                  <b-form-group
                    v-if="firstStageStructure === TournamentFormat.ROUND_ROBIN"
                  >
                    <template v-slot:label>
                      <label>Participants to advance in next stage from each group
                        <span class="text-primary">***</span><label /></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Participants to advance"
                      rules="integer"
                    >
                      <b-form-input
                        id="firstStageParticipantsToAdvanceInNextStage"
                        v-model="firstStageParticipantsToAdvanceInNextStage"
                        type="number"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Rank in groups by -->
                  <b-form-group
                    v-if="firstStageStructure === TournamentFormat.ROUND_ROBIN"
                  >
                    <template v-slot:label>
                      <label>Rank in groups by <span class="text-primary">***</span><label /></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Participants to advance"
                      rules="integer"
                    >
                      <v-select
                        id="type"
                        v-model="firstStageRankInGroupsBy"
                        disabled="disabled"
                        :options="GroupRankingOptions"
                        :reduce="(format) => format.code"
                        name="format"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Group TieBreaks #1 -->
                  <b-form-group
                    v-if="firstStageStructure === TournamentFormat.ROUND_ROBIN"
                  >
                    <template v-slot:label>
                      <label>Group Tie Breaks #1
                        <span class="text-primary">***</span><label /></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Group Tie Breaks"
                      rules="integer"
                    >
                      <v-select
                        id="type"
                        v-model="firstStageGroupTieBrakes1"
                        :options="GroupRankingOptions"
                        :reduce="(format) => format.code"
                        name="format"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Group TieBreaks #2 -->
                  <b-form-group
                    v-if="firstStageStructure === TournamentFormat.ROUND_ROBIN"
                  >
                    <template v-slot:label>
                      <label>Group Tie Breaks #2
                        <span class="text-primary">***</span><label /></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Group Tie Breaks"
                      rules="integer"
                    >
                      <v-select
                        id="type"
                        v-model="firstStageGroupTieBrakes2"
                        :options="GroupRankingOptions"
                        :reduce="(format) => format.code"
                        name="format"
                        :selectable="
                          (option) => {
                            return (
                              option.code !== 1 &&
                              option.code !== firstStageGroupTieBrakes1
                            );
                          }
                        "
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Group TieBreaks #3 -->
                  <b-form-group
                    v-if="firstStageStructure === TournamentFormat.ROUND_ROBIN"
                  >
                    <template v-slot:label>
                      <label>Group Tie Breaks #3
                        <span class="text-primary">***</span><label /></label>
                    </template>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="Group Tie Breaks"
                      rules="integer"
                    >
                      <v-select
                        id="type"
                        v-model="firstStageGroupTieBrakes3"
                        :options="GroupRankingOptions"
                        :reduce="(format) => format.code"
                        name="format"
                        :selectable="
                          (option) => {
                            return (
                              option.code !== 1 &&
                              option.code !== firstStageGroupTieBrakes2
                            );
                          }
                        "
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Second stage structure -->
                  <b-form-group v-if="enableTwoStageTournament === true">
                    <label
                      for="reason"
                    >Structure of second stage
                      <span class="text-primary">***</span></label>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="type"
                      rules="required"
                    >
                      <v-select
                        id="type"
                        v-model="secondStageStructure"
                        :options="StageTypeOptions"
                        :reduce="(option) => option.code"
                        name="type"
                        :selectable="
                          (option) => {
                            return (
                              option?.code !== TournamentFormat.ROUND_ROBIN
                            );
                          }
                        "
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- General Format -->
                  <b-form-group
                    v-if="secondStageStructure === TournamentFormat.SINGLE_ELIMINATION || secondStageStructure === TournamentFormat.DOUBLE_ELIMINATION"
                    class="flex-fill"
                  >
                    <label for="reason">Second stage general format</label>
                    <validation-provider
                      v-slot="{ errors: componentErrors }"
                      name="format"
                      :rules="generalFormatValidationRules"
                    >
                      <v-select
                        id="type"
                        v-model="secondStageGeneralFormat"
                        :options="MatchFormatOptions"
                        :reduce="format => format.code"
                        name="format"
                      />
                      <small class="text-danger">
                        {{ componentErrors[0] }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <hr>

                  <!-- Enable registrations -->
                  <b-form-checkbox
                    v-model="enableRegistration"
                    class="mt-1 mb-1"
                    :disabled="privateTournament === true"
                  >
                    Enable registrations
                  </b-form-checkbox>
                  <label>
                    If checked users have to register and join tournament on our
                    platform. If you are running offline event or you want to
                    enter players/teams and score manually then please uncheck
                    this option. When you start tournament empty bracket will
                    appear and you will have to enter teams/players.
                  </label>

                  <!-- Open registration-->
                  <div v-if="enableRegistration">
                    <label>Registration open
                      <span class="text-primary">***</span></label>
                    <b-row>
                      <b-col cols="6">
                        <b-form-datepicker
                          v-model="registrationOpenDate"
                          :locale="getUserLocale()"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                          :start-weekday="1"
                          class="mb-1"
                          name="checkin-start-date"
                          placeholder=""
                        />
                      </b-col>
                      <b-col cols="6">
                        <b-form-timepicker
                          v-model="registrationOpenTime"
                          :hour12="false"
                          locale="en"
                          placeholder=""
                        />
                      </b-col>
                    </b-row>

                    <!-- Close registration-->
                    <label>Registration close
                      <span class="text-primary">***</span></label>
                    <b-row>
                      <b-col cols="6">
                        <b-form-datepicker
                          v-model="registrationCloseDate"
                          :locale="getUserLocale()"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                          :start-weekday="1"
                          class="mb-1"
                          name="checkin-start-date"
                          placeholder=""
                        />
                      </b-col>
                      <b-col cols="6">
                        <b-form-timepicker
                          v-model="registrationCloseTime"
                          :hour12="false"
                          locale="en"
                          placeholder=""
                        />
                      </b-col>
                    </b-row>
                  </div>

                  <!-- Enable Checkin -->
                  <b-form-checkbox
                    v-model="enableCheckin"
                    class="mt-1 mb-1"
                  >
                    Enable Check-in
                  </b-form-checkbox>
                  <label class="mb-2">
                    If check-in is enabled teams/players need to check-in before
                    tournament starts and only checked-in teams/players can
                    participate. You can later setup when check-in starts and
                    ends. This option can be very useful for free tournaments to
                    avoid more than usual no-show matches in tournament. Keep in
                    mind that teams/players that don’t check-in in time and
                    tournament is started they can’t join and participate.
                  </label>

                  <!-- Checkin start-->
                  <div v-if="enableCheckin">
                    <label>Checkin start
                      <span class="text-primary">***</span></label>
                    <b-row>
                      <b-col cols="6">
                        <b-form-datepicker
                          v-model="checkinStartDate"
                          :locale="getUserLocale()"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                          :start-weekday="1"
                          class="mb-1"
                          name="checkin-start-date"
                          placeholder=""
                        />
                      </b-col>
                      <b-col cols="6">
                        <b-form-timepicker
                          v-model="checkinStartTime"
                          :hour12="false"
                          locale="en"
                          placeholder=""
                        />
                      </b-col>
                    </b-row>

                    <!-- Checking close-->
                    <label>Checkin close
                      <span class="text-primary">***</span></label>
                    <b-row>
                      <b-col cols="6">
                        <b-form-datepicker
                          v-model="checkinCloseDate"
                          :locale="getUserLocale()"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                          :start-weekday="1"
                          class="mb-1"
                          name="checkin-close-date"
                          placeholder=""
                        />
                      </b-col>
                      <b-col cols="6">
                        <b-form-timepicker
                          v-model="checkinCloseTime"
                          :hour12="false"
                          locale="en"
                          placeholder=""
                        />
                      </b-col>
                    </b-row>
                  </div>

                  <hr>
                  <!-- public tournament -->
                  <p>
                    Is tournament Public or Invite only?
                    <span class="text-primary">***</span>
                  </p>

                  <validation-provider
                    v-slot="{ errors: componentErrors }"
                    name="type"
                    rules="required"
                  >
                    <b-form-checkbox
                      v-model="publicTournament"
                      checked="true"
                      class="custom-control-primary"
                      name="enableTwoStageTournament"
                      @change="privateTournament = false"
                    >
                      PUBLIC - Any Team/player can join
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="privateTournament"
                      checked="true"
                      class="custom-control-primary mt-1"
                      name="enableTwoStageTournament"
                      @change="() => {publicTournament = false; enableRegistration = false;}"
                    >
                      PRIVATE - Only Team/player with invite can join!
                    </b-form-checkbox>
                    <small class="text-danger">
                      {{ componentErrors[0] }}
                    </small>
                  </validation-provider>

                  <p class="mt-1">
                    Auto-confirm join request to the tournament.
                    <span class="text-primary">***</span>
                  </p>
                  <validation-provider
                    v-slot="{ errors: componentErrors }"
                    name="type"
                    rules="required"
                  >
                    <b-form-checkbox
                      v-model="autoConfirmJoinRequest"
                      checked="true"
                      class="custom-control-primary"
                      name="autoConfirmJoinRequest"
                    >
                      Yes, auto-confirm after team/player joins
                    </b-form-checkbox>
                    <small class="text-danger">
                      {{ componentErrors[0] }}
                    </small>
                  </validation-provider>

                  <p class="mt-1">
                    Other tournaments settings
                  </p>
                  <validation-provider
                    v-slot="{ errors: componentErrors }"
                    name="type"
                    rules="required"
                  >
                    <b-form-checkbox
                      v-model="mandatoryMatchAttachment"
                      checked="true"
                      class="custom-control-primary"
                      name="matchAttachmentRequired"
                    >
                      Image match attachments MUST be uploaded
                    </b-form-checkbox>
                    <small class="text-danger">
                      {{ componentErrors[0] }}
                    </small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <!-- RIGHT tournament data -->
          <b-col
            sm="12"
            lg="3"
          >
            <!-- Prize data -->
            <b-card
              class=""
              title="Tournament type and prizes"
            >
              <b-row>
                <b-col cols="12">
                  <!-- Entry Fee? -->
                  <b-form-group>
                    <b-form-checkbox
                      v-model="hasEntryFee"
                      class="mt-1"
                    >
                      Enable participation fee
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- Fee per team/player -->
                  <b-form-group
                    v-if="hasEntryFee"
                    label="Entry fee for player/team"
                    label-for="fee-amount"
                  >
                    <b-form-input
                      id="fee-amount"
                      v-model="entryFee"
                      placeholder="Fee amount"
                      type="number"
                    />
                  </b-form-group>

                  <!-- Fee per team/player -->
                  <b-form-group
                    v-if="hasEntryFee"
                    label="Your fee in % (earning)"
                    label-for="fee-amount-percent"
                  >
                    <b-form-input
                      id="fee-amount-percent"
                      v-model="organizerFee"
                      placeholder="Fee amount"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- prize distribution -->
              <b-row v-if="entryFee" />

              <!-- additional prizes -->
              <p class="mt-1">
                Add your prizes
              </p>
              <div>
                <label>
                  Add your prizes If you have prizes that will be delivered by
                  you please enter them below (each prize in new line). Keep in
                  mind that its crucial for you to deliver promised prizes after
                  event. If you would like to give money prize to participants
                  please get in touch with us.
                </label>
                <b-form-textarea
                  v-model="prizes"
                  name="prizes"
                />
              </div>
            </b-card>

            <!-- Tournament staff -->
            <b-card class="">
              <p>Tournament staff <span class="text-primary">***</span></p>
              <validation-provider
                v-slot="{ errors: componentErrors }"
                name="Staff"
                rules="required"
              >
                <b-form-checkbox
                  v-for="member in organizer?.members"
                  :key="member.user?.id"
                  v-model="staff"
                  :button-variant="
                    staff && staff.indexOf(member.user?.id) !== -1
                      ? 'primary'
                      : 'outline-primary'
                  "
                  :value="member.user?.id"
                  button
                  class="mr-1"
                  size="sm"
                >
                  {{ member.user?.name }}
                </b-form-checkbox>

                <div class="mt-1">
                  <small class="text-danger">
                    {{ componentErrors[0] }}
                  </small>
                </div>
              </validation-provider>
            </b-card>

            <dismissible-message-box
              :auto-dismiss="5000"
              :show="showErrorNotifications"
              :variant="MessageType.ERROR"
              class="p-2"
            >
              <feather-icon
                class="mr-50"
                icon="InfoIcon"
              />
              {{ errors[0] }}
            </dismissible-message-box>

            <dismissible-message-box
              v-if="showSuccessNotifications"
              :auto-dismiss="5000"
              :show="showSuccessNotifications"
              :variant="MessageType.SUCCESS"
            >
              {{ successMessage }}
            </dismissible-message-box>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click.prevent="createTournament"
            >
              Create new tournament
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTextarea,
  BFormTimepicker,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import { required } from '@validations'
import TournamentType from '@/constants/TournamentType'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import TournamentFormat from '@/constants/TournamentFormat'
import { StageTypeOptions } from '@/constants/StageTypeOptions'
import { GroupRankingOptions } from '@/constants/GroupRankingOptions'
import { TournamentTypeOptions } from '@/constants/TournamentTypeOptions'
import { ParticipantTypeOptions } from '@/constants/ParticipantTypeOptions'
import { ParticipantType } from '@/constants/ParticipantType'
import tournamentFormMixin from '@/views/dashboard/tournament/manage/tournamentFormMixin'
import MessageType from '@/constants/MessageType'
import { MatchFormatOptions } from '@/constants/MatchFormatOptions'

export default {
  components: {
    DismissibleMessageBox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BButton,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormTimepicker,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [tournamentFormMixin],
  data() {
    return {
      // Import types
      MatchFormatOptions,
      MessageType,
      TournamentFormat,
      StageTypeOptions,
      GroupRankingOptions,
      TournamentTypeOptions,
      ParticipantTypeOptions,
      TournamentType,
      ParticipantType,
      TournamentParticipantType,
      required,

      isLoading: true,

      // Form data
      name: null,
      disciplineCategory: null,
      discipline: null,
      platforms: null,
      participantsType: ParticipantType.TEAM,
      participantsNumber: null,
      playersPerTeam: null,
      timezone: null,
      info: null,
      rules: null,

      enableTwoStageTournament: false,
      firstStageNumberOfGroups: null,
      firstStageParticipantsPerGroup: null,
      firstStageParticipantsToAdvanceInNextStage: null,
      firstStageRankInGroupsBy: 1,
      firstStageGroupTieBrakes1: null,
      firstStageGroupTieBrakes2: null,
      firstStageGroupTieBrakes3: null,
      firstStageStructure: null,
      firstStageGeneralFormat: null,
      secondStageStructure: null,
      secondStageGeneralFormat: null,

      enableRegistration: false,
      registrationOpenDate: null,
      registrationOpenTime: null,
      registrationCloseDate: null,
      registrationCloseTime: null,

      enableCheckin: false,
      checkinStartDate: null,
      checkinStartTime: null,
      checkinCloseDate: null,
      checkinCloseTime: null,

      privateTournament: false,
      publicTournament: true,

      autoConfirmJoinRequest: false,
      mandatoryMatchAttachment: false,
      hasEntryFee: false,
      entryFee: null,
      organizerFee: null,
      prizes: null,
      staff: [],

      generalFormat: null,
      thirdPlaceMatch: false,
      finalsHasOtherFormat: false,
      finalsFormat: null,
      semiFinalsFormat: null,
      semiFinalsHaveOtherFormat: false,
      lowerBracketFinalsHaveOtherFormat: false,
      lowerBracketFinalsFormat: null,

      startDate: null,
      startTime: null,

      checkinEndOnStart: false,

      // Form validation & api calls results
      successMessage: null,
      showErrorNotifications: false,
      showSuccessNotifications: false,
      errors: [],
    }
  },

  methods: {
    async createTournament() {
      this.showErrorNotifications = false
      this.showSuccessNotifications = false
      this.errors = []

      const formValid = await this.$refs.validation.validate()

      if (!formValid) {
        return
      }

      // eslint-disable-next-line no-unreachable
      const payload = {
        organizer_id: Number(this.$route.params.id),
        name: this.name,
        discipline_id: Number(this.discipline),
        image: null,
        participants_number: Number(this.participantsNumber),
        participant_type_id: this.participantsType,
        players_per_team: this.playersPerTeam
          ? Number(this.playersPerTeam)
          : null,
        timezone_id: this.timezone,
        platforms: this.platforms
          ? this.platforms.map(platform => platform.code)
          : [],
        info: this.info,
        rules: this.rules,

        settings: {
          enable_two_stage_tournament: this.enableTwoStageTournament,
          first_stage_number_of_groups: this.firstStageNumberOfGroups,
          first_stage_participants_per_group: this.firstStageParticipantsPerGroup,
          first_stage_participants_to_advance_in_next_stage: this.firstStageParticipantsToAdvanceInNextStage,
          first_stage_rank_in_groups_by: this.firstStageRankInGroupsBy,
          first_stage_group_tie_brakes1: this.firstStageGroupTieBrakes1,
          first_stage_group_tie_brakes2: this.firstStageGroupTieBrakes2,
          first_stage_group_tie_brakes3: this.firstStageGroupTieBrakes3,
          first_stage_structure: this.firstStageStructure,
          first_stage_general_format: this.firstStageGeneralFormat,
          second_stage_structure: this.secondStageStructure,
          second_stage_general_format: this.secondStageGeneralFormat,
          mandatory_match_attachment: this.mandatoryMatchAttachment,
          auto_confirm_join_request: this.autoConfirmJoinRequest,
        },

        start_at: `${this.startDate} ${this.startTime}`,

        enable_registration: this.enableRegistration,
        registration_open_at: this.enableRegistration
          ? `${this.registrationOpenDate} ${this.registrationOpenTime}`
          : null,
        registration_close_at: this.enableRegistration
          ? `${this.registrationCloseDate} ${this.registrationCloseTime}`
          : null,
        enable_checkin: this.enableCheckin,
        checkin_start_at: this.enableCheckin
          ? `${this.checkinStartDate} ${this.checkinStartTime}`
          : null,
        checkin_close_at: this.enableCheckin
          ? `${this.checkinCloseDate} ${this.checkinCloseTime}`
          : null,
        checkin_end_on_tournament_start: this.checkinEndOnStart,
        has_participation_fee: this.hasEntryFee,
        entry_fee: this.entryFee ? parseFloat(this.entryFee) : null,
        organizer_fee: this.organizerFee ? parseFloat(this.organizerFee) : null,
        staff: this.staff ? this.staff : null,
        prizes: this.prizes,
        tournament_type_id: this.publicTournament
          ? TournamentType.PUBLIC
          : TournamentType.PRIVATE,
      }

      const {
        data,
        errors,
      } = await this.$api.tournament.createTournament(
        payload,
      )

      if (errors && errors.length) {
        this.errors.push(
          'There was an error creating tournament. Please try again or contact our support team.',
        )

        this.showErrorNotifications = true

        return
      }

      this.successMessage = 'Tournament successfully created. You\'ll be redirected to tournament overview in 5 seconds.'
      this.showSuccessNotifications = true

      setTimeout(async () => {
        await this.$router.push({
          name: 'dashboard-tournament-overview',
          params: {
            id: Number(this.$route.params.id),
            tournamentId: data.id,
          },
        })
      }, 5000)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
