var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" Create new tournament ")]), _vm._m(0), _c('validation-observer', {
    ref: "validation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "9"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('b-form-group', {
    class: _vm.errors.length > 0 ? 'is-invalid' : null,
    attrs: {
      "description": "Example: League of Legends 5v5 EU West FUN"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Tournament name (max 70 characters) "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")])])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tournament name",
      "rules": "required",
      "vid": "tournament-name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "tournament-name",
            "maxlength": "70"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Discipline category "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")])])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "name": "Discipline category",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var componentErrors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "discipline-category",
            "options": _vm.fetchDisciplineCategories,
            "reduce": function reduce(category) {
              return category.code;
            }
          },
          model: {
            value: _vm.disciplineCategory,
            callback: function callback($$v) {
              _vm.disciplineCategory = $$v;
            },
            expression: "disciplineCategory"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Discipline / game "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")])])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "name": "Discipline",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var componentErrors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "discipline",
            "options": _vm.fetchDisciplines,
            "reduce": function reduce(d) {
              return d.code;
            }
          },
          model: {
            value: _vm.discipline,
            callback: function callback($$v) {
              _vm.discipline = $$v;
            },
            expression: "discipline"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "description": "if cross-play is supported you can select more than one"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Select platform "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")])])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "name": "Platform",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var componentErrors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "id": "platforms",
            "options": _vm.fetchPlatforms,
            "multiple": ""
          },
          model: {
            value: _vm.platforms,
            callback: function callback($$v) {
              _vm.platforms = $$v;
            },
            expression: "platforms"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Participant format ",
      "label-for": "participant-type"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "participant-type",
      "options": _vm.ParticipantTypeOptions,
      "name": "participant-type"
    },
    model: {
      value: _vm.participantsType,
      callback: function callback($$v) {
        _vm.participantsType = $$v;
      },
      expression: "participantsType"
    }
  })], 1), _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Maximum tournament participants (size) "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")])])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "name": "Participants number",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var componentErrors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "participants-number",
            "placeholder": "Enter number",
            "type": "number"
          },
          model: {
            value: _vm.participantsNumber,
            callback: function callback($$v) {
              _vm.participantsNumber = $$v;
            },
            expression: "participantsNumber"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _vm.participantsType === 1 ? _c('b-form-group', {
    attrs: {
      "description": "Enter minimum players that must be in team so the team is able to join"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Number of players per team "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")])])];
      },
      proxy: true
    }], null, false, 1680155061)
  }, [_c('validation-provider', {
    attrs: {
      "name": "Players per team",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var componentErrors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "players-per-team",
            "placeholder": "Enter number ...",
            "type": "number"
          },
          model: {
            value: _vm.playersPerTeam,
            callback: function callback($$v) {
              _vm.playersPerTeam = $$v;
            },
            expression: "playersPerTeam"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 3625809680)
  })], 1) : _vm._e(), _c('label', [_vm._v("Tournament start")]), _c('validation-provider', {
    attrs: {
      "name": "Tournament start",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var componentErrors = _ref7.errors;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-datepicker', {
          staticClass: "mb-1",
          attrs: {
            "locale": _vm.getUserLocale(),
            "date-format-options": {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            },
            "start-weekday": 1,
            "name": "start-date",
            "placeholder": ""
          },
          model: {
            value: _vm.startDate,
            callback: function callback($$v) {
              _vm.startDate = $$v;
            },
            expression: "startDate"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-timepicker', {
          attrs: {
            "hour12": false,
            "locale": "en",
            "placeholder": ""
          },
          model: {
            value: _vm.startTime,
            callback: function callback($$v) {
              _vm.startTime = $$v;
            },
            expression: "startTime"
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  }), _c('b-form-group', {
    staticClass: "mt-1 mb-2",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Timezone "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")])])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "name": "Timezone",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var componentErrors = _ref8.errors;
        return [_c('v-select', {
          class: {
            'is-invalid': !!componentErrors.length
          },
          attrs: {
            "id": "timezone",
            "options": _vm.timezonesList,
            "reduce": function reduce(timezone) {
              return timezone.code;
            }
          },
          model: {
            value: _vm.timezone,
            callback: function callback($$v) {
              _vm.timezone = $$v;
            },
            expression: "timezone"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "description": "HTML and CSS supported"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Please add all info for participants, mention sponsors etc."), _c('span', {
          staticClass: "text-success"
        }, [_vm._v("***")])])];
      },
      proxy: true
    }])
  }, [_c('b-form-textarea', {
    attrs: {
      "name": "participants-info"
    },
    model: {
      value: _vm.info,
      callback: function callback($$v) {
        _vm.info = $$v;
      },
      expression: "info"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "description": "HTML and CSS supported"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Please add only tournament rules "), _c('span', {
          staticClass: "text-success"
        }, [_vm._v("***")])])];
      },
      proxy: true
    }])
  }, [_c('b-form-textarea', {
    attrs: {
      "name": "tournament-rules"
    },
    model: {
      value: _vm.rules,
      callback: function callback($$v) {
        _vm.rules = $$v;
      },
      expression: "rules"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "checked": "true",
      "name": "enableTwoStageTournament",
      "disabled": "disabled"
    },
    model: {
      value: _vm.enableTwoStageTournament,
      callback: function callback($$v) {
        _vm.enableTwoStageTournament = $$v;
      },
      expression: "enableTwoStageTournament"
    }
  }, [_vm._v(" Two Stage Tournament ")]), _c('label', {
    staticClass: "font-italic"
  }, [_vm._v("* First stage groups, second stage proceed to second stage")]), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("Structure of first stage "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("***")])]), _c('validation-provider', {
    attrs: {
      "name": "type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var componentErrors = _ref9.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.StageTypeOptions,
            "reduce": function reduce(type) {
              return type.code;
            },
            "name": "type"
          },
          model: {
            value: _vm.firstStageStructure,
            callback: function callback($$v) {
              _vm.firstStageStructure = $$v;
            },
            expression: "firstStageStructure"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1), _vm.firstStageStructure === _vm.TournamentFormat.SINGLE_ELIMINATION || _vm.firstStageStructure === _vm.TournamentFormat.DOUBLE_ELIMINATION ? _c('b-form-group', {
    staticClass: "flex-fill"
  }, [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("General format")]), _c('validation-provider', {
    attrs: {
      "name": "format",
      "rules": _vm.generalFormatValidationRules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var componentErrors = _ref10.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.MatchFormatOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "format"
          },
          model: {
            value: _vm.firstStageGeneralFormat,
            callback: function callback($$v) {
              _vm.firstStageGeneralFormat = $$v;
            },
            expression: "firstStageGeneralFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 2269159469)
  })], 1) : _vm._e(), _vm.firstStageStructure === _vm.TournamentFormat.ROUND_ROBIN ? _c('b-form-group', {
    attrs: {
      "label": "Number of groups",
      "label-for": "firstStageNumberOfGroups"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Number of groups "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")]), _c('label')])];
      },
      proxy: true
    }], null, false, 1476085332)
  }, [_c('validation-provider', {
    attrs: {
      "name": "Number of groups",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var componentErrors = _ref11.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "firstStageNumberOfGroups",
            "type": "number"
          },
          model: {
            value: _vm.firstStageNumberOfGroups,
            callback: function callback($$v) {
              _vm.firstStageNumberOfGroups = $$v;
            },
            expression: "firstStageNumberOfGroups"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 3666718048)
  })], 1) : _vm._e(), _vm.firstStageStructure === _vm.TournamentFormat.ROUND_ROBIN ? _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Participants in each group (max 20) "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")]), _c('label')])];
      },
      proxy: true
    }], null, false, 48021406)
  }, [_c('validation-provider', {
    attrs: {
      "name": "Participants in group",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var componentErrors = _ref12.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "firstStageParticipantsPerGroup",
            "type": "number"
          },
          model: {
            value: _vm.firstStageParticipantsPerGroup,
            callback: function callback($$v) {
              _vm.firstStageParticipantsPerGroup = $$v;
            },
            expression: "firstStageParticipantsPerGroup"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 982549088)
  })], 1) : _vm._e(), _vm.firstStageStructure === _vm.TournamentFormat.ROUND_ROBIN ? _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Participants to advance in next stage from each group "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")]), _c('label')])];
      },
      proxy: true
    }], null, false, 3855977085)
  }, [_c('validation-provider', {
    attrs: {
      "name": "Participants to advance",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var componentErrors = _ref13.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "firstStageParticipantsToAdvanceInNextStage",
            "type": "number"
          },
          model: {
            value: _vm.firstStageParticipantsToAdvanceInNextStage,
            callback: function callback($$v) {
              _vm.firstStageParticipantsToAdvanceInNextStage = $$v;
            },
            expression: "firstStageParticipantsToAdvanceInNextStage"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 3719284160)
  })], 1) : _vm._e(), _vm.firstStageStructure === _vm.TournamentFormat.ROUND_ROBIN ? _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Rank in groups by "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")]), _c('label')])];
      },
      proxy: true
    }], null, false, 3201813108)
  }, [_c('validation-provider', {
    attrs: {
      "name": "Participants to advance",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var componentErrors = _ref14.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "disabled": "disabled",
            "options": _vm.GroupRankingOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "format"
          },
          model: {
            value: _vm.firstStageRankInGroupsBy,
            callback: function callback($$v) {
              _vm.firstStageRankInGroupsBy = $$v;
            },
            expression: "firstStageRankInGroupsBy"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 541721136)
  })], 1) : _vm._e(), _vm.firstStageStructure === _vm.TournamentFormat.ROUND_ROBIN ? _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Group Tie Breaks #1 "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")]), _c('label')])];
      },
      proxy: true
    }], null, false, 3730855371)
  }, [_c('validation-provider', {
    attrs: {
      "name": "Group Tie Breaks",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var componentErrors = _ref15.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.GroupRankingOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "format"
          },
          model: {
            value: _vm.firstStageGroupTieBrakes1,
            callback: function callback($$v) {
              _vm.firstStageGroupTieBrakes1 = $$v;
            },
            expression: "firstStageGroupTieBrakes1"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 3453673338)
  })], 1) : _vm._e(), _vm.firstStageStructure === _vm.TournamentFormat.ROUND_ROBIN ? _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Group Tie Breaks #2 "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")]), _c('label')])];
      },
      proxy: true
    }], null, false, 1393412136)
  }, [_c('validation-provider', {
    attrs: {
      "name": "Group Tie Breaks",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var componentErrors = _ref16.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.GroupRankingOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "format",
            "selectable": function selectable(option) {
              return option.code !== 1 && option.code !== _vm.firstStageGroupTieBrakes1;
            }
          },
          model: {
            value: _vm.firstStageGroupTieBrakes2,
            callback: function callback($$v) {
              _vm.firstStageGroupTieBrakes2 = $$v;
            },
            expression: "firstStageGroupTieBrakes2"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 4064867557)
  })], 1) : _vm._e(), _vm.firstStageStructure === _vm.TournamentFormat.ROUND_ROBIN ? _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('label', [_vm._v("Group Tie Breaks #3 "), _c('span', {
          staticClass: "text-primary"
        }, [_vm._v("***")]), _c('label')])];
      },
      proxy: true
    }], null, false, 344177033)
  }, [_c('validation-provider', {
    attrs: {
      "name": "Group Tie Breaks",
      "rules": "integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var componentErrors = _ref17.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.GroupRankingOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "format",
            "selectable": function selectable(option) {
              return option.code !== 1 && option.code !== _vm.firstStageGroupTieBrakes2;
            }
          },
          model: {
            value: _vm.firstStageGroupTieBrakes3,
            callback: function callback($$v) {
              _vm.firstStageGroupTieBrakes3 = $$v;
            },
            expression: "firstStageGroupTieBrakes3"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 1380692487)
  })], 1) : _vm._e(), _vm.enableTwoStageTournament === true ? _c('b-form-group', [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("Structure of second stage "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("***")])]), _c('validation-provider', {
    attrs: {
      "name": "type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var componentErrors = _ref18.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.StageTypeOptions,
            "reduce": function reduce(option) {
              return option.code;
            },
            "name": "type",
            "selectable": function selectable(option) {
              return (option === null || option === void 0 ? void 0 : option.code) !== _vm.TournamentFormat.ROUND_ROBIN;
            }
          },
          model: {
            value: _vm.secondStageStructure,
            callback: function callback($$v) {
              _vm.secondStageStructure = $$v;
            },
            expression: "secondStageStructure"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 2691149637)
  })], 1) : _vm._e(), _vm.secondStageStructure === _vm.TournamentFormat.SINGLE_ELIMINATION || _vm.secondStageStructure === _vm.TournamentFormat.DOUBLE_ELIMINATION ? _c('b-form-group', {
    staticClass: "flex-fill"
  }, [_c('label', {
    attrs: {
      "for": "reason"
    }
  }, [_vm._v("Second stage general format")]), _c('validation-provider', {
    attrs: {
      "name": "format",
      "rules": _vm.generalFormatValidationRules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var componentErrors = _ref19.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "options": _vm.MatchFormatOptions,
            "reduce": function reduce(format) {
              return format.code;
            },
            "name": "format"
          },
          model: {
            value: _vm.secondStageGeneralFormat,
            callback: function callback($$v) {
              _vm.secondStageGeneralFormat = $$v;
            },
            expression: "secondStageGeneralFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 1088960679)
  })], 1) : _vm._e(), _c('hr'), _c('b-form-checkbox', {
    staticClass: "mt-1 mb-1",
    attrs: {
      "disabled": _vm.privateTournament === true
    },
    model: {
      value: _vm.enableRegistration,
      callback: function callback($$v) {
        _vm.enableRegistration = $$v;
      },
      expression: "enableRegistration"
    }
  }, [_vm._v(" Enable registrations ")]), _c('label', [_vm._v(" If checked users have to register and join tournament on our platform. If you are running offline event or you want to enter players/teams and score manually then please uncheck this option. When you start tournament empty bracket will appear and you will have to enter teams/players. ")]), _vm.enableRegistration ? _c('div', [_c('label', [_vm._v("Registration open "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("***")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "locale": _vm.getUserLocale(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "start-weekday": 1,
      "name": "checkin-start-date",
      "placeholder": ""
    },
    model: {
      value: _vm.registrationOpenDate,
      callback: function callback($$v) {
        _vm.registrationOpenDate = $$v;
      },
      expression: "registrationOpenDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": ""
    },
    model: {
      value: _vm.registrationOpenTime,
      callback: function callback($$v) {
        _vm.registrationOpenTime = $$v;
      },
      expression: "registrationOpenTime"
    }
  })], 1)], 1), _c('label', [_vm._v("Registration close "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("***")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "locale": _vm.getUserLocale(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "start-weekday": 1,
      "name": "checkin-start-date",
      "placeholder": ""
    },
    model: {
      value: _vm.registrationCloseDate,
      callback: function callback($$v) {
        _vm.registrationCloseDate = $$v;
      },
      expression: "registrationCloseDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": ""
    },
    model: {
      value: _vm.registrationCloseTime,
      callback: function callback($$v) {
        _vm.registrationCloseTime = $$v;
      },
      expression: "registrationCloseTime"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-form-checkbox', {
    staticClass: "mt-1 mb-1",
    model: {
      value: _vm.enableCheckin,
      callback: function callback($$v) {
        _vm.enableCheckin = $$v;
      },
      expression: "enableCheckin"
    }
  }, [_vm._v(" Enable Check-in ")]), _c('label', {
    staticClass: "mb-2"
  }, [_vm._v(" If check-in is enabled teams/players need to check-in before tournament starts and only checked-in teams/players can participate. You can later setup when check-in starts and ends. This option can be very useful for free tournaments to avoid more than usual no-show matches in tournament. Keep in mind that teams/players that don’t check-in in time and tournament is started they can’t join and participate. ")]), _vm.enableCheckin ? _c('div', [_c('label', [_vm._v("Checkin start "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("***")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "locale": _vm.getUserLocale(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "start-weekday": 1,
      "name": "checkin-start-date",
      "placeholder": ""
    },
    model: {
      value: _vm.checkinStartDate,
      callback: function callback($$v) {
        _vm.checkinStartDate = $$v;
      },
      expression: "checkinStartDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": ""
    },
    model: {
      value: _vm.checkinStartTime,
      callback: function callback($$v) {
        _vm.checkinStartTime = $$v;
      },
      expression: "checkinStartTime"
    }
  })], 1)], 1), _c('label', [_vm._v("Checkin close "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("***")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "locale": _vm.getUserLocale(),
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "start-weekday": 1,
      "name": "checkin-close-date",
      "placeholder": ""
    },
    model: {
      value: _vm.checkinCloseDate,
      callback: function callback($$v) {
        _vm.checkinCloseDate = $$v;
      },
      expression: "checkinCloseDate"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "locale": "en",
      "placeholder": ""
    },
    model: {
      value: _vm.checkinCloseTime,
      callback: function callback($$v) {
        _vm.checkinCloseTime = $$v;
      },
      expression: "checkinCloseTime"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('hr'), _c('p', [_vm._v(" Is tournament Public or Invite only? "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("***")])]), _c('validation-provider', {
    attrs: {
      "name": "type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var componentErrors = _ref20.errors;
        return [_c('b-form-checkbox', {
          staticClass: "custom-control-primary",
          attrs: {
            "checked": "true",
            "name": "enableTwoStageTournament"
          },
          on: {
            "change": function change($event) {
              _vm.privateTournament = false;
            }
          },
          model: {
            value: _vm.publicTournament,
            callback: function callback($$v) {
              _vm.publicTournament = $$v;
            },
            expression: "publicTournament"
          }
        }, [_vm._v(" PUBLIC - Any Team/player can join ")]), _c('b-form-checkbox', {
          staticClass: "custom-control-primary mt-1",
          attrs: {
            "checked": "true",
            "name": "enableTwoStageTournament"
          },
          on: {
            "change": function change() {
              _vm.publicTournament = false;
              _vm.enableRegistration = false;
            }
          },
          model: {
            value: _vm.privateTournament,
            callback: function callback($$v) {
              _vm.privateTournament = $$v;
            },
            expression: "privateTournament"
          }
        }, [_vm._v(" PRIVATE - Only Team/player with invite can join! ")]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  }), _c('p', {
    staticClass: "mt-1"
  }, [_vm._v(" Auto-confirm join request to the tournament. "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("***")])]), _c('validation-provider', {
    attrs: {
      "name": "type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref21) {
        var componentErrors = _ref21.errors;
        return [_c('b-form-checkbox', {
          staticClass: "custom-control-primary",
          attrs: {
            "checked": "true",
            "name": "autoConfirmJoinRequest"
          },
          model: {
            value: _vm.autoConfirmJoinRequest,
            callback: function callback($$v) {
              _vm.autoConfirmJoinRequest = $$v;
            },
            expression: "autoConfirmJoinRequest"
          }
        }, [_vm._v(" Yes, auto-confirm after team/player joins ")]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  }), _c('p', {
    staticClass: "mt-1"
  }, [_vm._v(" Other tournaments settings ")]), _c('validation-provider', {
    attrs: {
      "name": "type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref22) {
        var componentErrors = _ref22.errors;
        return [_c('b-form-checkbox', {
          staticClass: "custom-control-primary",
          attrs: {
            "checked": "true",
            "name": "matchAttachmentRequired"
          },
          model: {
            value: _vm.mandatoryMatchAttachment,
            callback: function callback($$v) {
              _vm.mandatoryMatchAttachment = $$v;
            },
            expression: "mandatoryMatchAttachment"
          }
        }, [_vm._v(" Image match attachments MUST be uploaded ")]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Tournament type and prizes"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "mt-1",
    model: {
      value: _vm.hasEntryFee,
      callback: function callback($$v) {
        _vm.hasEntryFee = $$v;
      },
      expression: "hasEntryFee"
    }
  }, [_vm._v(" Enable participation fee ")])], 1), _vm.hasEntryFee ? _c('b-form-group', {
    attrs: {
      "label": "Entry fee for player/team",
      "label-for": "fee-amount"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "fee-amount",
      "placeholder": "Fee amount",
      "type": "number"
    },
    model: {
      value: _vm.entryFee,
      callback: function callback($$v) {
        _vm.entryFee = $$v;
      },
      expression: "entryFee"
    }
  })], 1) : _vm._e(), _vm.hasEntryFee ? _c('b-form-group', {
    attrs: {
      "label": "Your fee in % (earning)",
      "label-for": "fee-amount-percent"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "fee-amount-percent",
      "placeholder": "Fee amount",
      "type": "number"
    },
    model: {
      value: _vm.organizerFee,
      callback: function callback($$v) {
        _vm.organizerFee = $$v;
      },
      expression: "organizerFee"
    }
  })], 1) : _vm._e()], 1)], 1), _vm.entryFee ? _c('b-row') : _vm._e(), _c('p', {
    staticClass: "mt-1"
  }, [_vm._v(" Add your prizes ")]), _c('div', [_c('label', [_vm._v(" Add your prizes If you have prizes that will be delivered by you please enter them below (each prize in new line). Keep in mind that its crucial for you to deliver promised prizes after event. If you would like to give money prize to participants please get in touch with us. ")]), _c('b-form-textarea', {
    attrs: {
      "name": "prizes"
    },
    model: {
      value: _vm.prizes,
      callback: function callback($$v) {
        _vm.prizes = $$v;
      },
      expression: "prizes"
    }
  })], 1)], 1), _c('b-card', {}, [_c('p', [_vm._v("Tournament staff "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("***")])]), _c('validation-provider', {
    attrs: {
      "name": "Staff",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref23) {
        var _vm$organizer;
        var componentErrors = _ref23.errors;
        return [_vm._l((_vm$organizer = _vm.organizer) === null || _vm$organizer === void 0 ? void 0 : _vm$organizer.members, function (member) {
          var _member$user, _member$user2, _member$user3, _member$user4;
          return _c('b-form-checkbox', {
            key: (_member$user = member.user) === null || _member$user === void 0 ? void 0 : _member$user.id,
            staticClass: "mr-1",
            attrs: {
              "button-variant": _vm.staff && _vm.staff.indexOf((_member$user2 = member.user) === null || _member$user2 === void 0 ? void 0 : _member$user2.id) !== -1 ? 'primary' : 'outline-primary',
              "value": (_member$user3 = member.user) === null || _member$user3 === void 0 ? void 0 : _member$user3.id,
              "button": "",
              "size": "sm"
            },
            model: {
              value: _vm.staff,
              callback: function callback($$v) {
                _vm.staff = $$v;
              },
              expression: "staff"
            }
          }, [_vm._v(" " + _vm._s((_member$user4 = member.user) === null || _member$user4 === void 0 ? void 0 : _member$user4.name) + " ")]);
        }), _c('div', {
          staticClass: "mt-1"
        }, [_c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])])];
      }
    }])
  })], 1), _c('dismissible-message-box', {
    staticClass: "p-2",
    attrs: {
      "auto-dismiss": 5000,
      "show": _vm.showErrorNotifications,
      "variant": _vm.MessageType.ERROR
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.errors[0]) + " ")], 1), _vm.showSuccessNotifications ? _c('dismissible-message-box', {
    attrs: {
      "auto-dismiss": 5000,
      "show": _vm.showSuccessNotifications,
      "variant": _vm.MessageType.SUCCESS
    }
  }, [_vm._v(" " + _vm._s(_vm.successMessage) + " ")]) : _vm._e(), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.createTournament($event);
      }
    }
  }, [_vm._v(" Create new tournament ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" You can later edit fields marked with "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("***")]), _vm._v(". Before and after tournament starts you can edit only fields marked with "), _c('span', {
    staticClass: "text-success"
  }, [_vm._v("***")]), _vm._v(". "), _c('br'), _vm._v(" Try to enter accurate information when creating tournament. "), _c('b', [_vm._v("Please check our "), _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("documentation to understand settings")]), _vm._v("!")])]);
}]

export { render, staticRenderFns }