// eslint-disable-next-line import/prefer-default-export
export const TournamentTypeOptions = Object.freeze([
  {
    text: 'Public',
    value: 1,
  },
  {
    text: 'Private',
    value: 2,
  },
])
